<template>
  <h2 class="pre-check-title">符規標準</h2>

  <Tabs>
    <Tab pathName="PreCheckList" title="符規標準列表" />
    <Tab pathName="AgainstRules" title="未符規設備列表" />
    <Tab pathName="PathSource" title="更新檔來源" />
  </Tabs>

  <div class="main-list-view">
    <!-- 列表容器 -->
    <div class="main-list-container"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'

export default defineComponent({
  components: {
    Tabs,
    Tab
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
