
import { defineComponent } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'

export default defineComponent({
  components: {
    Tabs,
    Tab
  },
  setup() {
    return {}
  }
})
